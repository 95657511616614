// arabic
export const locale = {
  title: {
    title: 'Engage',
  },
  nav: {
    notification: 'تنبيهات',
    surveyYear: 'سنة المسح',
    language: 'اللغه',
    signout: 'تسجيل الخروج',
    login: 'تسجيل الدخول',
    register: 'الاشتراك',
  },
  leftMenu: {
    home: 'الصفحه الرئيسيه',
    users: 'المستخدمين',
    subscription: 'الاشتراك',
    subscriptionRequests: 'الاشتراكات المقدمه',
    subscriptionList: {
      manageSubscriptionPackage: 'إدارة حزم الاشتراك',
      manageSubscriptions: 'ازارة الاشتراكات',
    },
    structureLookup: 'بحث الهيكل',
    structureLookupList: {
      economicSector: 'القطاع الاقتصادي',
      industryType: 'نوع الصناعه',
      businessSector: 'قطاع الاعمال',
      businessSubSector: 'قطاع الاعمال الغرعي',
      subscriptionType: 'انواع الحزم',
      jobFamily: 'انواع الوظائف',
      jobTitle: 'الوظائف',
      setup: 'الاعدادات',
      setupList: {
        surveyYear: 'سنة المسح',
        legalStructureLookup: 'ادارة الهياكل القانونيه',
      },
    },
    marketAnalysis: 'تحليل السوق',
    marketAnalysisList: {
      marketInsights: 'رؤي السوق',
      marketPayTrends: 'اتجاهات السوق',
    },
    customer: {
      customers: 'العملاء',
      profile: 'الملف الشخصي',
      positionMapping: 'تعيين الموقف',
      analysisReport: 'تقرير التحليل',
      positionReport: 'تقرير الموقف',
      reportGenerator: 'منشيء التقارير',
      dashboard: 'لوحة القياده',
      jobTitles: 'الوظائف',
      reports: 'تقارير',
    },
  },
  registerationPackages: {
    register: 'تسجيل',
    packagePeriod: 'فترة الباقه',
    numberOfUsers: 'عدد المستخدمين',
    numberOfBusinessSectors: 'عدد قطاعات الاعمال',
    subscribe: 'اشتراك',
  },
  login: {
    login: 'تسجيل الدخول',
    username: 'اسم المستخدم',
    password: 'كلمة السر',
    register: 'تسجيل',
    donothaveaccount: 'ليس لديك حساب ؟',
    enteruserpassword: 'فضلا أدخل اسم المستخدم وكلمة المرور',
  },
  adminDashboard: {
    dashboard: 'لوحة القياده',
    report1Header: 'عدد المشتركين المشاركين في المسح الي الان',
    report2Header: 'نسبة استكمال بيانات العميل',
    systemNotification: 'اشعارات النظام',
    Task: 'مهمه',
    Ticket: 'تذكرة',
    Meeting: 'اجتماع',
    All: 'الكل',
    Scheduled: 'مقرر',
    InProgress: 'قيد التنفيذ',
    Done: 'انتهي',
    System: 'النظام',
    Admin: 'المشرف',
    Customer: 'العميل',
    Me: 'اشعاراتي',
    Normal: 'طبيعي',
    High: 'مرتفع',
    id: 'معرف السجل',
    notificationType: 'نوع الاشعار',
    notificationTime: 'وقت الاشعار',
    importance: 'الاهميه',
    notificationStatus: 'حالة الاشعار',
  },
  users: {
    users: 'المستخدمين',
    roles: 'الادوار',
    addUser: 'اضافة مستخدم',
    grid: {
      name: 'الاسم',
      arabicName: 'الاسم عربي',
      userName: 'اسم المستخدم',
      password: 'كلمة السر',
      mobile: 'رقم الجوال',
      gender: 'الجنس',
      joinDate: 'تاريخ الاشتراك',
      eMail: 'البريد الالكتروني',
      notes: 'ملاحظات',
      User_Rights: 'حقوق المستخدم',
      action: 'التصرفات',
    },
    confirmPassword: 'تأكيد كلمة السر',
    rolesList: {
      superAdmin: 'المشرف المتميز',
      customerSupport: 'خدمة العملاء',
      systemAdmin: 'مشرف النظام',
      user: 'المستخدم',
    },
    add: 'اضافة',
    update: 'تعديل',
    addNew: 'اضافة مستخدم جديد',
    edit: 'تعديل مستخدم النظام',
  },
  registerationManage: {
    id: 'التعريف',
    legalName: 'الاسم القانوني',
    commercialName: 'الاسم التجاري',
    legalStructure: 'الهيكل القانوني',
    yearOfEstablishment: 'سنة التأسيس',
    industryType: 'نوع الصناعة',
    businessSector: 'مجال الاعمال',
    businessSubSector: 'مجال الاعمال الفرعي',
    contactPerson: 'الشخص الذي يمكن الاتصال به',
    contactPersonMail: 'البريد الالكتروني للتواصل',
    contactPersonMobile: 'هاتف الجوال للتواصل',
    webSite: 'الموقع',
    Action: 'الاعمال المتاحه',
    remove: 'ازاله',
    registerationMgr: 'ادارة التسجيلات',
    noRegisterations: 'لا توجد تسجيلات حاليه',
  },
  subscriptionPackages: {
    grid: {
      id: 'التعريف',
      packageName: 'اسم الحزمة',
      packagePeriodName: 'اسم فترة الحزمة',
      numberOfBusinessSectors: 'عدد قطاع الاعمال',
      numberOfUsers: 'عدد المستخدمين',
      Action: 'الاعمال المتاحه',
      Reports: 'التقارير',
    },
    title: 'ادارة حزم الاشتراكات',
    add: 'اضافة حزمة اشتراك جديده',
    edit: 'تعديل حزمة اشتراك',
    update: 'تعديل',
    submit: 'تنفيذ',
    packageName: 'اسم الحزمه',
    packagePeriod: 'فترة الحزمه',
    businessSector: 'قطاع الاعمال',
    numberOfUsers: 'عدد المستخدمين',
    noPackages: 'لا يوجد حزم اشتراكات حاليه',
  },
  economicSector: {
    title: 'القطاع الاقتصادي',
    add: 'اضافة قطاع اقتصادي جديد',
    grid: {
      id: 'التعريف',
      englishName: 'الاسم بالاجليزي',
      arabicName: 'الاسم بالعربي',
      Action: 'الاعمال المتاحه',
    },
    englishName: 'الاسم بالاجليزي',
    arabicName: 'الاسم بالعربي',
    submit: 'اضافة',
    update: 'تعديل',
    addHeader: 'اضافة قطاع اقتصادي',
    editHeader: 'تعديل قطاع اقتصادي',
  },
  IndustryType: {
    title: 'نوع الصناعه',
    add: 'اضافة نوع صناعه جديد',
    grid: {
      id: 'التعريف',
      economicSectorName: 'القطاع الاقتضادي',
      englishName: 'الاسم بالنجليزيه',
      arabicName: 'الاسم بالعربيه',
      Action: 'الاعمال المتاحه',
    },
    economicSector: 'القطاع الاقتصادي',
    englishName: 'الاسم بالانجليزيه',
    arabicName: 'الاسم بالعربيه',
    submit: 'اضافة',
    update: 'تعديل',
    addHeader: 'اضافة نوع صناعه جديد',
    editHeader: 'تعديل نوع صناعه',
  },
  BusinessSector: {
    title: 'قطاع الاعمال',
    add: 'اضافة قطاع اعمال جديد',
    grid: {
      id: 'التعريف',
      industryTypeName: 'اسم نوع الصناعه',
      englishName: 'الاسم بالانجليزيه',
      arabicName: 'الاسم بالعربيه',
      Action: 'الاعمال المتاحه',
    },
    industryType: 'نوع الصناعه',
    englishName: 'الاسم بالانجليزيه',
    arabicName: 'الاسم بالعربيه',
    submit: 'اضافه',
    update: 'تعديل',
    addHeader: 'اضافة قطاع اعمال',
    editHeader: 'تعديل قطاع اعمال',
  },
  TRANSLATOR: {
    SELECT: '选择你的语言',
  },
  MENU: {
    NEW: '新',
    ACTIONS: '行动',
    CREATE_POST: '创建新帖子',
    PAGES: 'Pages',
    FEATURES: '特征',
    APPS: '应用',
    DASHBOARD: '仪表板',
  },
  AUTH: {
    GENERAL: {
      OR: '要么',
      SUBMIT_BUTTON: '提交',
      NO_ACCOUNT: '没有账号？',
      SIGNUP_BUTTON: '注册',
      FORGOT_BUTTON: '忘记密码',
      BACK_BUTTON: '背部',
      PRIVACY: '隐私',
      LEGAL: '法律',
      CONTACT: '联系',
    },
    LOGIN: {
      TITLE: '创建帐号',
      BUTTON: '签到',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: '用戶名',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: '顾客',
      CUSTOMERS_LIST: '客户名单',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
};
