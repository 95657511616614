import Vue from "vue";
import App from "./App.vue";
import Axios from 'axios';
import router from "./router";
import store from "./core/services/store";
import VueScrollTo from 'vue-scrollto';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

// import ApiService from "./core/services/api.service";
// import MockService from "./core/mock/mock.service";
import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import {

  FormCheckboxPlugin,
} from 'bootstrap-vue';
import NProgress from 'vue-nprogress';
import vuelidate from 'vuelidate';
import VueSignalR from '@latelier/vue-signalr';
//import VueSmartWidget from 'vue-smart-widget';
import { currency } from '@/currency';
import { VueMaskDirective } from 'v-mask';
import number from '@coders-tm/vue-number-format';

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";


Vue.prototype.$baseURL = 'http://localhost:57917/API';

Vue.config.productionTip = false;
Vue.use(VueScrollTo);

Vue.use(Buefy);
Vue.use(FormCheckboxPlugin);
Vue.use(NProgress);
Vue.use(vuelidate);
Vue.use(VueSignalR, 'http://localhost:55023/API/SubscriptionPackage');

Vue.use(number, { precision: 4 });
Vue.prototype.$axios = Axios;
Vue.filter('currency', currency);
Vue.directive('mask', VueMaskDirective);

var numeral = require('numeral');

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
});
// API service init
//ApiService.init();

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
