import decode from 'jwt-decode'
import axios from 'axios'

const REST_ENDPOINT = 'http://localhost:55022/API'
const AUTH_TOKEN_KEY = 'userAuthToken'

export function loginUser(username, password) {
    const w = new Promise( (resolve, reject) => {
        try {
             axios({
                url: `${REST_ENDPOINT}/Account`,
                method: 'POST',
                data: {
                    username: username,
                    password: password,
                    grant_type: 'password'
                }
            }).then((result)=>{
                setAuthToken(result.data.token.token)
            });
            resolve()
        } catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)
        }
    });
    return Promise.resolve(w);
}

export function logoutUser() {
    clearAuthToken()
}

export function setAuthToken(token) {
    // console.log('authentication token: ')
    // console.log(token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    const isLogged = !!authToken && !isTokenExpired(authToken)


    return isLogged;
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}

