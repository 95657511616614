// import Vue from "vue";
// import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';

// Vue.use(Vuex);

// export default new Vuex.Store({
//   modules: {
//     auth,
//     htmlClass,
//     config,
//     breadcrumbs
//   }
// });
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import router from '../router'
// import * as Cookies from 'js-cookie'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userDetails: {
      surveyYear: '',
      userId: '',
      username: '',
      name: 'user1',
      subscriptionId: 11,
      subscriptionPaclageId: -1,
      customerName: '',
      isFirstLogin: false,
      roles: [],
      adminSelectedCustomer: null,
    },
    userId: '',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    SubscriptionRequestPackage: {
      id: -1,
      packageName: '',
      subscriptionPeriod: -1,
      SubscriptionFees: 0,
    },
    notifications: 0,
    countries: [],
    cities: [],
    industryTypes: [],
    businessSectors: [],
    businessSubSectors: [],
    legalStructures: [],
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  getters: {
    customer: (state) =>
      state.userDetails.customerName == null
        ? 'No Customer'
        : state.userDetails.customerName,
    surveyYear: (state) => state.userDetails.surveyYear,
    isFirstLogin: (state) => state.userDetails.isFirstLogin,
    userId: (state) => state.userDetails.userId,
    getSubscriptionRequestPackage: (state) => state.SubscriptionRequestPackage,
    getNotifications: (state) => state.notifications,
    getSubscriptionPackage: (state) => state.userDetails.subscriptionPaclageId,
    getCountries: (state) => state.countries,
    getCities: (state) => state.cities,
    getIndustryTypes: (state) => state.industryTypes,
    getBusinessSectors: (state) => state.businessSectors,
    getBusinessSubSectors: (state) => state.businessSubSectors,
    getLegalStructures: (state) => state.legalStructures,
  },
  mutations: {
    updateCountries(state, payload) {
      if (payload.length == 0) {
        state.countries.push(payload);
      } else {
        state.countries.concat(payload);
      }
    },
    updateCities(state, payload) {
      // if(state.cities == null){
      //     state.cities = [];
      // }
      if (payload == null) return;
      if (payload.length == 0) {
        state.cities.push(payload);
      } else {
        state.cities.concat(payload);
      }
    },
    updateIndustryTypes(state, payload) {
      // console.log('updateIndustryTypes')
      // console.log(payload)
      state.industryTypes.push(payload);
      // console.log(state.industryTypes)
    },
    updateBusinessSectors(state, payload) {
      state.businessSectors.push(payload);
    },
    updateBusinessSubSectors(state, payload) {
      state.businessSubSectors.push(payload);
    },
    updateLegalStructures(state, payload) {
      state.legalStructures.push(payload);
    },
    resetUserDetails(state) {
      state.userDetails.surveyYear = '';
      [];
      state.userDetails.userId = '';
      state.userDetails.username = '';
      state.userDetails.subscriptionId = null;
      state.userDetails.customerName = '';
    },
    changeSelectedCustomer(state, customer) {
      state.adminSelectedCustomer = customer;
    },
    changeSurveyYear(state, surveyYear) {
      state.userDetails.surveyYear = surveyYear;
    },
    addNotification(state, payload) {
      state.notifications = payload;
    },
    changeUserName(state, payload) {
      state.username = payload.userName;
    },
    changeUserID(state, payload) {
      state.userId = payload.userName;
    },
    updateUserDetails(state, details) {
      state.userDetails = details;
    },
    updateIsFirstLogin(state) {
      state.userDetails = !state.userDetails;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    set_requestPackage(state, payload) {
      state.SubscriptionRequestPackage = payload;
    },
    set_packagePeriod(state, payload) {
      state.packagePeriod = payload;
    },
  },
  actions: {
    updateRequestPackage({ commit }, payload) {
      commit('set_requestPackage', payload);
    },
    // getUserDetails({commit},payload)
    // {
    //     return new Promise((resolve,reject)=>{
    //         try{
    //             this.$axios({
    //               headers: {'Content-Type': 'application/json' },
    //               url: `${this.$baseURL}/Account/user?UserName=${payload}`,
    //               method: 'GET',
    //               data: {
    //                 UserName: payload,
    //               }
    //           }).then((response)=>{
    //             if(JSON.parse(response.data.status) == true){
    //                 commit("updateUserDetails", response.data.userDetails);
    //                 resolve();
    //                   if(response.data.userDetails.subscriptionId !=null
    //                     && response.data.userDetails.subscriptionId > 0) //customer account
    //                   {
    //                       router.push({ path: '/Customer/CustomerDashboard'});
    //                   }
    //                   else{
    //                       router.push({ path: '/AdminDashboard'});
    //                   }
    //               }
    //             });
    //         }
    //         catch(err)
    //         {
    //           reject(err);
    //         }
    //     });
    // },
    updateSurveyYear({ commit }, payload) {
      commit('changeSurveyYear', payload);
    },

    addNotification({ commit }, payload) {
      commit('addNotification', payload);
    },
  },
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
  },
});
